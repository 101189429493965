<template>

    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center; height: 100vh;">

        <router-view v-slot="{ Component }">
            <keep-alive>
                <component :is="Component"/>
            </keep-alive>
        </router-view>

    </div>

    <Notifies/>
    <BlockNotify/>
    <ConfirmPopup/>

</template>

<script>

import {BlockNotify, Notifies} from "@xnpmpackages/xcomponents";

export default {
    name: "Main",
    components: {
        Notifies,
        BlockNotify
    }
}
</script>

<style scoped>

</style>